<script setup lang="ts">
import { ADMIN_PAGES } from '~/utils/admin-pages';
import { EMAIL_VALIDATOR, SUPER_ADMIN_ROLE } from '~/constants';
import type { Database, Tables } from '~/supabase/types/database.types';
import { useApprovalApi } from '~/hooks/use-approval-api';

const supabase = useSupabaseClient<Database>();
const approvalApi = useApprovalApi();
const user = useSupabaseUser();

const emailInput = ref('');
const emailInputError = ref();

const discordUsernameInput = ref('');
const contributionInput = ref('');

const errorMessage = ref();
const successMessage = ref();
const infoMessage = ref();

const isSignedUp = ref(false);

const isUser = ref(!!user?.value);
const userEmail = ref(user?.value?.email);
const isUserWaitingForApproval = ref(false);
const isDiscord = ref(false);
const isContribution = ref(false);

const isLoading = ref(false);

const hasError = computed(() => {
  return !!emailInputError.value || !emailInput.value;
});

async function handleEmailInput(customEvent: any) {
  const event = customEvent.detail.event;
  const input = event.target as HTMLInputElement;
  emailInputError.value = '';
  emailInput.value = input.value;
  // if no value is entered we just exit here
  if (!input.value) {
    return;
  }
  // check if address has valid format
  if (!EMAIL_VALIDATOR.test(input.value)) {
    emailInputError.value = 'Invalid email address';
    return;
  }
}

async function handleDiscordUsernameInput(customEvent: any) {
  const event = customEvent.detail.event;
  const input = event.target as HTMLInputElement;
  discordUsernameInput.value = input.value;
}

async function handleContributionInput(customEvent: any) {
  const event = customEvent.detail.event;
  const input = event.target as HTMLInputElement;
  contributionInput.value = input.value;
}

async function signUp() {
  isLoading.value = true;
  try {
    const { error } = await supabase.auth.signInWithOtp({
      email: emailInput.value,
      options: {
        shouldCreateUser: true,
      },
    });
    if (error) {
      throw error;
    }
    successMessage.value = '📧 We have sent you an email';
    isSignedUp.value = true;
    isLoading.value = false;
  } catch (error) {
    isUser.value = false;
    isLoading.value = false;
    errorMessage.value = error;
    console.log('❌ERROR singUp user', error);
  }
}

const handleLoggedInUser = async () => {
  isLoading.value = true;

  if (!user?.value?.email) {
    return;
  }

  if (user.value?.app_metadata.role === SUPER_ADMIN_ROLE) {
    await navigateTo(ADMIN_PAGES[0].to);

    return;
  }

  let client: Tables<'clients'> | null = null;
  try {
    const clientResponse = await approvalApi.getClientByEmail(
      user?.value?.email,
    );
    client = clientResponse?.data;
  } catch (error) {
    isUser.value = false;
    errorMessage.value = error;
    console.log('❌ERROR getting client', error);

    return;
  }

  if (!client) {
    try {
      // await registerClient({
      //   email: user?.value?.email,
      // });
    } catch (error) {
      isUser.value = false;
      errorMessage.value = error;
      console.log('❌ERROR inserting user', error);
    }
  } else {
    if (client?.is_accepted === true) {
      await navigateTo('/mobile-app');

      return;
    }
  }

  isDiscord.value = !!client?.discord;
  isContribution.value = !!client?.contribution;
  isUserWaitingForApproval.value = true;
  isLoading.value = false;
};

const addUserInformation = async () => {
  isLoading.value = true;
  infoMessage.value = '';

  if (!user?.value?.email) {
    errorMessage.value = 'No user email found';

    return;
  }

  if (!discordUsernameInput.value && !contributionInput.value) {
    infoMessage.value = 'Please provide at least one of information fields';

    return;
  }

  let userInformation = {
    discordUsername: discordUsernameInput.value,
    contribution: contributionInput.value,
  };

  const { data, error } = await approvalApi.updateOptionalClientInformation(
    user?.value?.email,
    userInformation,
  );

  console.log(data, error);
  if (error) {
    errorMessage.value = error;
    return;
  }

  isDiscord.value = !!discordUsernameInput.value;
  isContribution.value = !!contributionInput.value;
  isUserWaitingForApproval.value = true;
  isLoading.value = false;
};

watchEffect(() => {
  if (!!user?.value?.email === true) {
    handleLoggedInUser();
  }
});
</script>

<template>
  <LoginCard>
    <div
      v-show="isUser === false"
      class="bg-light-grey flex flex-col rounded-md p-8 shadow-lg gap-5"
    >
      <div>
        <div class="text-center">
          Sign up for the UniversalProfile mobile app
        </div>
      </div>
      <lukso-input
        :value="emailInput"
        :error="emailInputError"
        is-full-width
        @on-input="handleEmailInput"
      ></lukso-input>

      <lukso-button
        :disabled="hasError ? true : undefined"
        is-full-width
        class="mt-6"
        @click="signUp"
      >
        Send Magic Link
      </lukso-button>
    </div>
    <div
      v-show="
        isUser === true &&
        isLoading === false &&
        isUserWaitingForApproval === true
      "
      class="flex-col justify-between"
    >
      <p>👋 Hello {{ userEmail }}.</p>
      <p>
        Thank you for joining the wait list for the UniversalProfile 📱 mobile
        app
      </p>
      <div v-show="isDiscord === false || isContribution === false">
        <p class="px-2 py-2">OPTIONAL: We would like to know more about you</p>
        <div v-show="isDiscord === false">
          <p class="px-2 py-2">👩‍💻 What is your discord username?</p>
          <lukso-input
            :value="discordUsernameInput"
            placeholder="discord username"
            is-full-width
            @on-input="handleDiscordUsernameInput"
          ></lukso-input>
        </div>
        <div v-show="isContribution === false">
          <p class="px-2 py-2">👩‍🎤 What is your role in the LUKSO community?</p>
          <lukso-input
            :value="contributionInput"
            placeholder="developer, grantee..."
            is-full-width
            @on-input="handleContributionInput"
          ></lukso-input>
        </div>

        <lukso-button is-full-width class="mt-6" @click="addUserInformation">
          Add these optional information
        </lukso-button>
      </div>
    </div>
    <div class="flex flex-col text-center">
      <div>
        {{ errorMessage }}
      </div>
      <div>
        {{ infoMessage }}
      </div>
      <div>
        {{ successMessage }}
      </div>
    </div>
    <Loader :isLoading="isLoading" />
  </LoginCard>
</template>
